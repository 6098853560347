'use client'

import { useCallback, useState } from "react";
import { APIProvider } from "@vis.gl/react-google-maps";
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTrigger } from "./sheet";
import { Button } from "./button";
import { useToast } from "./use-toast";
import { useCurrentLocale, useI18n } from "@repo/libs/providers/locales/client";
import { MapsCustomField } from "./maps";
import { Coordinate, MapsCustomFieldOptions } from "@repo/libs/types/customFields";

interface MapPickerProps {
  initialValue?: string;
  onChange?: (value: string) => void;
  onValidate?: (position: Coordinate) => boolean | Promise<boolean>;
  mapsOptions?: MapsCustomFieldOptions;
  children: React.ReactNode;
  apiKey?: string;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
}

export function MapPicker({
  initialValue,
  onChange,
  onValidate,
  mapsOptions,
  children,
  apiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API || "",
  isOpen: controlledIsOpen,
  onOpenChange: controlledOnOpenChange,
}: MapPickerProps) {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);
  const dir = useCurrentLocale() === "ar" ? "rtl" : "ltr";
  const t = useI18n();
  const { toast } = useToast();

  const isControlled = controlledIsOpen !== undefined;
  const isOpen = isControlled ? controlledIsOpen : uncontrolledIsOpen;
  const setIsOpen = isControlled ? controlledOnOpenChange : setUncontrolledIsOpen;

  const [position, setPosition] = useState<Coordinate>(() => {
    if (initialValue) {
      const [lat, lng] = initialValue.split(',').map(Number);
      return { lat, lng };
    }
    return { lat: 24.710845, lng: 46.673693 };
  });

  const validatePosition = useCallback(async (
    latLng: Coordinate,
    savedPolygonsData?: MapsCustomFieldOptions,
  ): Promise<boolean> => {
    if (onValidate) {
      return onValidate(latLng);
    }

    if (!savedPolygonsData?.polygons?.length) {
      return true;
    }

    if (!google.maps.geometry) {
      return new Promise((resolve) =>
        setTimeout(() => resolve(validatePosition(latLng, savedPolygonsData)), 500)
      );
    }

    return savedPolygonsData.polygons.some((polygonData) => {
      const polygon = new google.maps.Polygon({ paths: polygonData.coordinates });
      return google.maps.geometry!.poly.containsLocation(latLng, polygon);
    });
  }, [onValidate]);

  const handleSave = async () => {
    const isValid = await validatePosition(position, mapsOptions);

    if (!isValid) {
      toast({
        title: t("location.outside.error"),
        description: t("location.outside.error.description"),
        variant: "destructive",
        duration: 1000,
      });
      return;
    }

    onChange?.(`${position.lat},${position.lng}`);
    setIsOpen?.(false);
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <div className="w-full">
          {children}
        </div>
      </SheetTrigger>

      <SheetContent
        dir={dir}
        onOpenAutoFocus={(e) => e.preventDefault()}
        side="bottom"
        className="flex h-[92.5svh] flex-col rounded-t-xl p-4 md:p-6"
      >
        <SheetHeader className="text-start sm:text-start">
          {t("location.set")}
        </SheetHeader>

        <APIProvider apiKey={apiKey}>
          <MapsCustomField
            pinPosition={position}
            setPinPosition={setPosition}
            mapsOptions={mapsOptions}
          />
        </APIProvider>

        <SheetFooter className="w-full items-center justify-center pb-4">
          <Button
            type="button"
            className="mt-4 w-full"
            onClick={handleSave}
          >
            {t("continue")}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
