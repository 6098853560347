import { useFormContext } from "react-hook-form";
import { CustomField, MapsCustomFieldOptions } from "@repo/libs/types/customFields";
import { MapPicker } from "./map-picker";
import { Input } from "./input";
import { MapPin, Pencil } from "lucide-react";
import { cn } from "../lib/utils";
import { useI18n } from "@repo/libs/providers/locales/client";

export function MapPickerFormField({ field }: { field: CustomField }) {
  const t = useI18n()
  const { setValue, trigger, getValues } = useFormContext();

  const handleChange = async (value: string) => {
    setValue(field.name, value);
    await trigger(field.name);
  };

  const isPositionSet = getValues()[field.name];

  return (
    <MapPicker
      onChange={handleChange}
      mapsOptions={field.mapsOptions}
    >
      <div className="flex gap-x-2">
        <Input
          value={isPositionSet ? t("location.selected") : t("location.select")}
          readOnly
          className={cn(isPositionSet ? "text-green-700" : "text-gray-700")}
        />
        <div
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 ring-offset-theme-background focus-visible:ring-theme-primary bg-theme-primary text-theme-background hover:bg-theme-primary-600 h-10 px-4 py-2 shrink-0 gap-x-2"
        >
          {isPositionSet ? <Pencil size={16} /> : <MapPin size={16} />}
        </div>
      </div>
    </MapPicker>
  );
}
